import React from "react";
import { Link as GatsbyLink } from "gatsby";
import styles from "./Link.module.scss";

const Link = ({ children, mark, to, style, href }) => {
  href = mark && mark.href;

  return to ? (
    <GatsbyLink className={styles.link} to={to}>
      <span>{children}</span>
    </GatsbyLink>
  ) : (
    <a className={styles.link} href={href}>
      <span>{children}</span>
    </a>
  );
};

export default Link;
