import React from "react";
import PropTypes from "prop-types";
import styles from "./ListView.module.scss";
import Image from "../../Image";
import BlockContent from "../BlockContent";

const ListItem = ({ title, text, image }) => {
  return (
    <div className={styles.listItem}>
      <div className={styles.text}>
        <h3>{title}</h3>
        <BlockContent blocks={text} />
      </div>
      <Image image={image} width={600} />
    </div>
  );
};

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.array,
  image: PropTypes.object,
};

export default ListItem;
