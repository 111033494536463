import React from "react";
import ListItem from "./ListItem";
import styles from "./ListView.module.scss";

const ListView = ({ node }) => {
  const { list } = node;

  return (
    <div className={styles.listView}>
      {list.map(({ image, text, title, _key }) => (
        <ListItem title={title} image={image} text={text} key={_key} />
      ))}
    </div>
  );
};

export default ListView;
