import React from "react";
import PortableText from "@sanity/block-content-to-react";
import styles from "./BlockContent.module.scss";
import ListView from "./ListView";
import Link from "../Link/Link";
import File from "./File";
import Image from "../Image";

const BlockContent = ({ blocks }) => {
  return (
    <div className={styles.blockContentWrapper}>
      <PortableText
        blocks={blocks}
        serializers={{
          types: {
            fileUpload: File,
            listView: ListView,
            image: ({ node }) => {
              return <Image image={node} />;
            },
            undefined: () => null,
          },
          marks: {
            link: Link,
          },
        }}
      />
    </div>
  );
};

export default BlockContent;
