import React from "react";
import PropTypes from "prop-types";
import styles from "./File.module.scss";

const File = (props) => {
  let { title, file } = props.node;
  if (!file && !title) return null;
  let { url = "", extension = "" } = file.asset;

  return url ? (
    <a
      icon="file"
      href={`${url}?dl=${title}.${extension}`}
      className={styles.file}
    >
      <span>{title}</span>
    </a>
  ) : (
    ""
  );
};

File.propTypes = {
  node: PropTypes.shape({
    title: PropTypes.string.isRequired,
    file: PropTypes.object.isRequired,
  }),
};

export default File;
